import React from 'react';

import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Accounts from '../pages/Accounts';
import Outgoings from '../pages/Outgoings';
import Incomings from '../pages/Incomings';
import Transfers from '../pages/Transfers';
import Goals from '../pages/Goals';
import Plan from '../pages/Plan';
import CashFlow from '../pages/CashFlow';
import PolicyPrivacy from '../pages/PolicyPrivacy';
import UseTerm from '../pages/UseTerm';
import Support from '../pages/Support';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/policy" component={PolicyPrivacy} />
    <Route path="/useterm" component={UseTerm} />
    <Route path="/support" component={Support} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/accounts" component={Accounts} isPrivate />
    <Route path="/outgoings" component={Outgoings} isPrivate />
    <Route path="/incomings" component={Incomings} isPrivate />
    <Route path="/transfers" component={Transfers} isPrivate />
    <Route path="/goals" component={Goals} isPrivate />
    <Route path="/plan" component={Plan} isPrivate />
    <Route path="/cashflow" component={CashFlow} isPrivate />
    <Route path="/appstore" component={CashFlow} isPrivate />
  </Switch>
);

export default Routes;
