import React, { useCallback, useRef } from 'react';
import { FiMail, FiUser, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  IFormatError,
  errorValidation,
  formatError,
} from '../../utils/errorValidation';

import dictionary from '../../locale/pt/dictionary.json';

import { Input, Button } from '../../components/atoms';
import { Container, Content, PasswordBlock, AvatarInput } from './styles';
import { ProfileFormData } from './interfaces';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';
import { Header } from '../../components/molecules';

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();

  const formRef = useRef<FormHandles>(null);
  const {
    ProfilePage: { ErrorsStrings, FormStrings, SuccessStrings },
  } = dictionary;
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          username: Yup.string().required(ErrorsStrings.NameRequired),
          email: Yup.string()
            .required(ErrorsStrings.EmailRequired)
            .email(ErrorsStrings.InvalidEmail),
          oldPassword: Yup.string(),
          password: Yup.string().when('oldPassword', {
            is: val => !!val.length,
            then: Yup.string()
              .required(ErrorsStrings.PasswordRequired)
              .min(6, ErrorsStrings.InvalidPassword),
            otherwise: Yup.string().min(0),
          }),
          confirmPassword: Yup.string().when('oldPassword', {
            is: val => !!val.length,
            then: Yup.string()
              .required(ErrorsStrings.ConfirmPasswordRequired)
              .oneOf(
                [Yup.ref('password'), ''],
                ErrorsStrings.PasswordDoesNotMatch,
              ),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        const userDataChange: ProfileFormData = {
          username: data.username,
          email: data.email,
        };

        if (data.oldPassword) {
          const passwordChange = {
            ...userDataChange,
            oldPassword: data.oldPassword,
            password: data.password,
            passwordConfirmation: data.confirmPassword,
          };

          await api.put(`/users`, passwordChange);
        } else {
          await api.put(`/users`, userDataChange);
        }

        updateUser({
          username: data.username,
          secureId: user.secureId,
          email: data.email,
          avatarUrl: `https://api.dicebear.com/7.x/bottts-neutral/svg?seed=${data.username}&scale=90`,
        });

        addToast({
          type: 'success',
          title: SuccessStrings.ToastTitle,
          description: SuccessStrings.ToastMessage,
        });

        formRef.current?.clearField('oldPassword');
        formRef.current?.clearField('password');
        formRef.current?.clearField('confirmPassword');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = errorValidation(err);

          formRef.current?.setErrors(errors);
          return;
        }

        const errors = formatError(err as IFormatError);

        addToast({
          type: 'error',
          title: ErrorsStrings.ToastTitle,
          description: errors[0].message,
        });
      }
    },
    [ErrorsStrings, SuccessStrings, history, addToast],
  );

  return (
    <Container>
      <Header backButtonVisible />
      <Content>
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <AvatarInput>
            <img
              src={`https://api.dicebear.com/7.x/bottts-neutral/svg?seed=${user.username}&scale=90`}
              alt={user.username}
            />
          </AvatarInput>
          <h1>{FormStrings.Title}</h1>

          <Input
            name="username"
            leftIcon={FiUser}
            placeholder={FormStrings.NamePlaceholder}
          />
          <Input
            name="email"
            disabled
            leftIcon={FiMail}
            placeholder={FormStrings.EmailPlaceholder}
          />

          <PasswordBlock>
            <Input
              name="oldPassword"
              leftIcon={FiLock}
              type="password"
              placeholder={FormStrings.OldPasswordPlaceholder}
            />
            <Input
              name="password"
              leftIcon={FiLock}
              type="password"
              placeholder={FormStrings.PasswordPlaceholder}
            />
            <Input
              name="confirmPassword"
              leftIcon={FiLock}
              type="password"
              placeholder={FormStrings.ConfirmPasswordPlaceholder}
            />
          </PasswordBlock>

          <Button type="submit">{FormStrings.ButtonConfirm}</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
