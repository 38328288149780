import styled from 'styled-components';
import { shade } from 'polished';
import Colors from '../../../styles/colors.json';

export const CalendarContainer = styled.div`
  width: 350px;

  .rdp {
    border-radius: 0.6rem;
  }

  .rdp-wrapper {
    padding-bottom: 0;
    border-radius: 0.6rem;
  }

  .rdp,
  .rdp-month {
  }

  .rdp-nav_button_previous {
    right: 1.5em;
    left: auto;
    margin-right: 0;
  }

  .rdp-nav_button_next {
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${Colors.gray};
  }

  .rdp-months {
    border-collapse: separate;
    border-spacing: 4px 6px;
    border-radius: 0 0 10px 10px;
  }

  .rdp-caption_label {
    color: ${Colors.secondary};

    > div {
      text-align: center;
    }
  }

  .rdp-head_row {
    color: ${Colors.secondary};
  }

  .rdp-day {
    width: 2rem;
    height: 2.2rem;
    font-size: 12px;
  }

  .rdp-day_available:not(.rdp-day_outside) {
    background: ${Colors.blackTransparent};
    border-radius: 0.6rem;
  }

  .rdp:not(.rdp_interactionDisabled)
    .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):not(
      .rdp-day_outside
    ):hover {
    background: ${shade(0.2, `${Colors.gray}`)};
  }

  .rdp-day_today {
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: normal;
    color: ${Colors.secondary};
  }

  .rdp-day_disabled {
    color: ${Colors.primary};
    background: transparent !important;
  }

  .rdp-day_selected {
    background: ${Colors.primary} !important;
    border-radius: 0.6rem;
    color: ${Colors.light} !important;
  }
`;
