import React from 'react';
import { Header } from '../../components/molecules';
import colors from '../../styles/colors.json';

const Support: React.FC = () => {
  return (
    <>
      <Header logoVisible backButtonVisible>
        <h1
          style={{
            boxSizing: 'border-box',
            marginInline: '20px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Sheep Finance - Suporte
        </h1>
      </Header>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 20,
          flexDirection: 'column',
        }}
      >
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Para entrar em contato com o suporte, envie um e-mail para:
          <b> contact@abeldutraui.me </b>
          ou pelo
          <b> Discord:</b>
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            backgroundColor: colors.grayHard,
            flexDirection: 'column',
            borderRadius: '6px',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <iframe
              title="Discord"
              src="https://discord.com/widget?id=968328932279599215&theme=dark"
              width="350"
              height="500"
              frameBorder={0}
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            />

            <div
              style={{
                padding: '10px',
                maxWidth: '300px',
              }}
            >
              <p>
                Olá,
                <br />
                Seja bem vindo ao suporte do
                <b> Sheep Finance</b>
                !
                <br />
                <br />
                Aqui você pode tirar suas dúvidas e nos ajudar a melhorar o
                aplicativo.
                <br />
                <br />
                Acesse o nosso servidor no Discord e fique a vontade para
                perguntar o que quiser.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
