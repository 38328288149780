import React from 'react';

// import { Container } from './styles';

const PolicyPrivacy: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
      <div
        style={{
          maxWidth: 700,
        }}
      >
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>1. Termos</span>
        </h2>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            Ao acessar ao site Sheep Finance, concorda em cumprir estes termos
            de serviço, todas as leis e regulamentos aplicáveis e concorda que é
            responsável pelo cumprimento de todas as leis locais aplicáveis. Se
            você não concordar com algum desses termos, está proibido de usar ou
            acessar este site. Os materiais contidos neste site são protegidos
            pelas leis de direitos autorais e marcas comerciais aplicáveis.
          </span>
        </p>
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>2. Uso de Licença</span>
        </h2>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            É concedida permissão para baixar temporariamente uma cópia dos
            materiais (informações ou software) no site Sheep Finance , apenas
            para visualização transitória pessoal e não comercial. Esta é a
            concessão de uma licença, não uma transferência de título e, sob
            esta licença, você não pode:&nbsp;
          </span>
        </p>
        <ol
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>modificar ou copiar os materiais;&nbsp;</span>
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>
              usar os materiais para qualquer finalidade comercial ou para
              exibição pública (comercial ou não comercial);&nbsp;
            </span>
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>
              tentar descompilar ou fazer engenharia reversa de qualquer
              software contido no site Sheep Finance;&nbsp;
            </span>
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>
              remover quaisquer direitos autorais ou outras notações de
              propriedade dos materiais; ou&nbsp;
            </span>
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>
              transferir os materiais para outra pessoa ou espelhe os materiais
              em qualquer outro servidor.
            </span>
          </li>
        </ol>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            Esta licença será automaticamente rescindida se você violar alguma
            dessas restrições e poderá ser rescindida por Sheep Finance a
            qualquer momento. Ao encerrar a visualização desses materiais ou
            após o término desta licença, você deve apagar todos os materiais
            baixados em sua posse, seja em formato eletrónico ou impresso.
          </span>
        </p>
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>3. Isenção de responsabilidade</span>
        </h2>
        <ol
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>
              Os materiais no site da Sheep Finance são fornecidos como estão.
              Sheep Finance não oferece garantias, expressas ou implícitas, e,
              por este meio, isenta e nega todas as outras garantias, incluindo,
              sem limitação, garantias implícitas ou condições de
              comercialização, adequação a um fim específico ou não violação de
              propriedade intelectual ou outra violação de direitos.
            </span>
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            <span style={{}}>
              Além disso, o Sheep Finance não garante ou faz qualquer
              representação relativa à precisão, aos resultados prováveis ou à
              confiabilidade do uso dos materiais em seu site ou de outra forma
              relacionado a esses materiais ou em sites vinculados a este site.
            </span>
          </li>
        </ol>
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>4. Limitações</span>
        </h2>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            Em nenhum caso o Sheep Finance ou seus fornecedores serão
            responsáveis por quaisquer danos (incluindo, sem limitação, danos
            por perda de dados ou lucro ou devido a interrupção dos negócios)
            decorrentes do uso ou da incapacidade de usar os materiais em Sheep
            Finance, mesmo que Sheep Finance ou um representante autorizado da
            Sheep Finance tenha sido notificado oralmente ou por escrito da
            possibilidade de tais danos. Como algumas jurisdições não permitem
            limitações em garantias implícitas, ou limitações de
            responsabilidade por danos conseqüentes ou incidentais, essas
            limitações podem não se aplicar a você.
          </span>
        </p>
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>5. Precisão dos materiais</span>
        </h2>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            Os materiais exibidos no site da Sheep Finance podem incluir erros
            técnicos, tipográficos ou fotográficos. Sheep Finance não garante
            que qualquer material em seu site seja preciso, completo ou atual.
            Sheep Finance pode fazer alterações nos materiais contidos em seu
            site a qualquer momento, sem aviso prévio. No entanto, Sheep Finance
            não se compromete a atualizar os materiais.
          </span>
        </p>
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>6. Links</span>
        </h2>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            O Sheep Finance não analisou todos os sites vinculados ao seu site e
            não é responsável pelo conteúdo de nenhum site vinculado. A inclusão
            de qualquer link não implica endosso por Sheep Finance do site. O
            uso de qualquer site vinculado é por conta e risco do usuário.
          </span>
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          &nbsp;
        </p>
        <h3
          style={{
            boxSizing: 'border-box',
            margin: '0px 0px 20px',
            lineHeight: '1.2',
            fontSize: '16px',
            letterSpacing: '-0.05em',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>Modificações</span>
        </h3>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            O Sheep Finance pode revisar estes termos de serviço do site a
            qualquer momento, sem aviso prévio. Ao usar este site, você concorda
            em ficar vinculado à versão atual desses termos de serviço.
          </span>
        </p>
        <h3
          style={{
            boxSizing: 'border-box',
            margin: '0px 0px 20px',
            lineHeight: '1.2',
            fontSize: '16px',
            letterSpacing: '-0.05em',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span style={{}}>Lei aplicável</span>
        </h3>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: 'normal',
          }}
        >
          <span style={{}}>
            Estes termos e condições são regidos e interpretados de acordo com
            as leis do Sheep Finance e você se submete irrevogavelmente à
            jurisdição exclusiva dos tribunais naquele estado ou localidade.
          </span>
        </p>
      </div>
    </div>
  );
};

export default PolicyPrivacy;
