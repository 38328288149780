import React, { ReactNode } from 'react';

import { AuthProvider } from './Auth';
import { AccountProvider } from './Account';
import { DashboardProvider } from './Dashboard';
import { CashFlowProvider } from './CashFlow';
import { GoalProvider } from './Goal';
import { CategoryProvider } from './Category';
import { OutgoingProvider } from './Outgoing';
import { TransferProvider } from './Transfer';
import { IncomingProvider } from './Incoming';
import { ToastProvider } from './Toast';
import { DateProvider } from './Date';
import { PlanProvider } from './Plan';

interface Props {
  children: ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }) => (
  <AuthProvider>
    <ToastProvider>
      <DateProvider>
        <DashboardProvider>
          <CashFlowProvider>
            <PlanProvider>
              <AccountProvider>
                <GoalProvider>
                  <CategoryProvider>
                    <OutgoingProvider>
                      <IncomingProvider>
                        <TransferProvider>{children}</TransferProvider>
                      </IncomingProvider>
                    </OutgoingProvider>
                  </CategoryProvider>
                </GoalProvider>
              </AccountProvider>
            </PlanProvider>
          </CashFlowProvider>
        </DashboardProvider>
      </DateProvider>
    </ToastProvider>
  </AuthProvider>
);

export default AppProvider;
