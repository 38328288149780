import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../../services/api';
import { CashFlowState, CashFlowContextData, Props } from './interfaces';

const CashFlowContext = createContext<CashFlowContextData>(
  {} as CashFlowContextData,
);

export const CashFlowProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<CashFlowState>({
    planIssue: {
      fixed: false,
      investment: false,
      variable: false,
    },
    realizedIssue: {
      fixed: false,
      investment: false,
      variable: false,
    },
  } as CashFlowState);

  const getCashFlow = useCallback(
    async (startDate: string, endDate: string) => {
      if (startDate && endDate) {
        setData({ ...data, loading: true });
        const response = await api.get<CashFlowState>(
          `cashflow/${startDate}/${endDate}`,
        );

        const {
          totalIncomings,
          totalVariable,
          totalFixed,
          totalInvestments,
          totalIncomingsPlan,
          totalVariablePlan,
          totalFixedPlan,
          totalInvestmentsPlan,

          incomings,
          investments,
          variableOutgoings,
          fixedOutgoings,
        } = response.data;

        const sumRealized =
          Number(totalVariable) + Number(totalFixed) + Number(totalInvestments);
        const sumPlan =
          Number(totalVariablePlan) +
          Number(totalFixedPlan) +
          Number(totalInvestmentsPlan);

        const notSetSum =
          Number(totalIncomings) -
          (Number(totalVariable) +
            Number(totalFixed) +
            Number(totalInvestments));

        const notSetPlanSum =
          Number(totalIncomingsPlan) -
          (Number(totalVariablePlan) +
            Number(totalFixedPlan) +
            Number(totalInvestmentsPlan));

        const unallocatedResourcePlan =
          notSetPlanSum < 0 ? 0.001 : notSetPlanSum;
        const unallocatedResource = notSetSum < 0 ? 0.001 : notSetSum;

        const planIssue = {
          investment:
            totalInvestmentsPlan / (sumPlan + unallocatedResourcePlan) < 0.2,
          fixed: totalFixedPlan / (sumPlan + unallocatedResourcePlan) > 0.5,
          variable:
            totalVariablePlan / (sumPlan + unallocatedResourcePlan) > 0.3,
        };

        const realizedIssue = {
          investment:
            totalInvestments / (sumRealized + unallocatedResource) < 0.2,
          fixed: totalFixed / (sumRealized + unallocatedResource) > 0.5,
          variable: totalVariable / (sumRealized + unallocatedResource) > 0.3,
        };

        setData({
          totalIncomings,
          totalIncomingsPlan,
          totalFixed,
          totalFixedPlan,
          totalVariable,
          totalVariablePlan,
          totalInvestments,
          totalInvestmentsPlan,

          incomings,
          variableOutgoings,
          fixedOutgoings,
          investments,

          planIssue,
          realizedIssue,
          unallocatedResource,
          unallocatedResourcePlan,

          loading: false,
        });
      }
    },
    [],
  );

  return (
    <CashFlowContext.Provider
      value={{
        totalIncomings: Number(data.totalIncomings),
        totalFixed: Number(data.totalFixed),
        totalInvestments: Number(data.totalInvestments),
        totalVariable: Number(data.totalVariable),
        totalIncomingsPlan: Number(data.totalIncomingsPlan),
        totalFixedPlan: Number(data.totalFixedPlan),
        totalInvestmentsPlan: Number(data.totalInvestmentsPlan),
        totalVariablePlan: Number(data.totalVariablePlan),

        incomings: data.incomings,
        variableOutgoings: data.variableOutgoings,
        fixedOutgoings: data.fixedOutgoings,
        investments: data.investments,

        planIssue: data.planIssue,
        realizedIssue: data.realizedIssue,
        unallocatedResource: data.unallocatedResource,
        unallocatedResourcePlan: data.unallocatedResourcePlan,

        loading: data.loading,
        getCashFlow,
      }}
    >
      {children}
    </CashFlowContext.Provider>
  );
};

export function useCashFLow(): CashFlowContextData {
  const context = useContext(CashFlowContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
