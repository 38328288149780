import styled, { css } from 'styled-components';

import { LogoProps } from './interfaces';

export const Body = styled.div``;

export const Image = styled.img<LogoProps>`
  max-width: 40px;
  min-width: 40px;

  ${props =>
    props.size === 'medium' &&
    css`
      max-width: 50px;
    `}

  ${props =>
    props.size === 'big' &&
    css`
      max-width: 100px;
    `}
`;
