import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Content } from './styles';
import { Button, Input } from '../../components/atoms';
import { Props } from './interfaces';
import dictionary from '../../locale/pt/dictionary.json';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import {
  IFormatError,
  errorValidation,
  formatError,
} from '../../utils/errorValidation';

const ConfirmCode: React.FC<Props> = ({ email, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<string>('');
  const formRef = useRef<FormHandles>(null);
  const {
    ConfirmCodePage: { ErrorsStrings, SuccessStrings },
  } = dictionary;
  const { sendCode } = useAuth();
  const { addToast } = useToast();

  const handleSendCode = useCallback(async () => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        code: Yup.string()
          .required(ErrorsStrings.CodeRequired)
          .min(5, ErrorsStrings.CodeInvalid),
      });

      await schema.validate({ code }, { abortEarly: false });

      await sendCode({
        code,
        email,
      });

      addToast({
        type: 'success',
        title: SuccessStrings.ToastTitle,
        description: SuccessStrings.ToastMessage,
      });
      successCallback(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = errorValidation(err);
        formRef.current?.setErrors(errors);
        setLoading(false);
        return;
      }
      const errors = formatError(err as IFormatError);
      addToast({
        type: 'error',
        title: ErrorsStrings.ToastTitle,
        description: errors[0].message,
      });
      successCallback(false);
    }
    setLoading(false);
  }, [code]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSendCode}>
        <Content>
          <h2>Confirme seu e-mail</h2>
          <span>{`Um e-mail foi enviado para ${email} com seu código de confirmação, certifique-se de que o e-mail não foi para sua caixa de spam.`}</span>
          <span>Insira o código recebido no campo abaixo!</span>

          <Input
            name="code"
            placeholder="Código de confirmação"
            maxLength={5}
            onChange={e => {
              console.log(e.target.value);
              setCode(e.target.value);
            }}
          />
          <Button loading={loading} onClick={handleSendCode}>
            Confirmar
          </Button>
        </Content>
      </Form>
    </Container>
  );
};

export default ConfirmCode;
