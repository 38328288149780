import React from 'react';
import { LogoProps } from './interfaces';

import { Body, Image } from './styles';

import logo from '../../../assets/logo.png';

const Logo: React.FC<LogoProps> = ({ size }) => (
  <Body>
    <Image size={size} src={logo} />
  </Body>
);

export default Logo;
