import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '../../../hooks/Auth';
import { Button, Logo, DynamicContent, ButtonBack } from '../../atoms';
import Profile from '../Profile';
import { HeaderProps } from './interfaces';
import { Container, Content } from './styles';

const Header: React.FC<HeaderProps> = ({
  backButtonVisible,
  logoVisible,
  exitButtonVisible,
  profileVisible,
  children,
}) => {
  const { user, signOut } = useAuth();
  return (
    <Container>
      <Content>
        <DynamicContent visible={!!backButtonVisible}>
          <ButtonBack />
        </DynamicContent>
        <DynamicContent visible={!!logoVisible}>
          <Logo size="medium" />
        </DynamicContent>
        <DynamicContent visible={!!profileVisible}>
          <Profile
            name={user && user.username}
            src={`https://api.dicebear.com/7.x/bottts-neutral/svg?seed=${
              user && user.username
            }&scale=90`}
            // src={`https://api.hello-avatar.com/adorables/${user.username}`}
            // src={`https://ui-avatars.com/api/?size=128&rounded=true&name=${user.username}`}
            alt="profile image"
          />
        </DynamicContent>
        <DynamicContent visible={!!children}>{children}</DynamicContent>
        <DynamicContent
          visible={!!exitButtonVisible}
          style={{ marginLeft: 'auto' }}
        >
          <Button buttonType="transparent" onClick={signOut}>
            <FiLogOut />
          </Button>
        </DynamicContent>
      </Content>
    </Container>
  );
};

export default Header;
