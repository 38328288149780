import React from 'react';

// import { Container } from './styles';

const PolicyPrivacy: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
      <div
        style={{
          maxWidth: 700,
        }}
      >
        <h2
          style={{
            boxSizing: 'border-box',
            margin: '0px',
            lineHeight: '1.2',
            fontSize: '20px',
            letterSpacing: '-0.05em',
            paddingBottom: '20px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Política Privacidade
        </h2>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          A sua privacidade é importante para nós. É política do Sheep Finance
          respeitar a sua privacidade em relação a qualquer informação sua que
          possamos coletar no site Sheep Finance, e outros sites que possuímos e
          operamos.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemo-lo por meios justos e
          legais, com o seu conhecimento e consentimento. Também informamos por
          que estamos coletando e como será usado.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Apenas retemos as informações coletadas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemos
          dentro de meios comercialmente aceitáveis para evitar perdas e roubos,
          bem como acesso, divulgação, cópia, uso ou modificação não
          autorizados.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Você é livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de Aviso de Privacidade e informações
          pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do
          usuário e informações pessoais, entre em contacto connosco.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        />
        <h3
          style={{
            boxSizing: 'border-box',
            margin: '0px 0px 20px',
            lineHeight: '1.2',
            fontSize: '16px',
            letterSpacing: '-0.05em',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Compromisso do Usuário
        </h3>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          O usuário se compromete a fazer uso adequado dos conteúdos e da
          informação que o Sheep Finance oferece no site e com caráter
          enunciativo, mas não limitativo:
        </p>
        <ul
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
          }}
        >
          <li style={{ boxSizing: 'border-box' }}>
            A) Não se envolver em atividades que sejam ilegais ou contrárias à
            boa fé a à ordem pública;
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            B) Não difundir propaganda ou conteúdo de natureza racista,
            xenofóbica, onde apostar ou azar, qualquer tipo de pornografia
            ilegal, de apologia ao terrorismo ou contra os direitos humanos;
          </li>
          <li style={{ boxSizing: 'border-box' }}>
            C) Não causar danos aos sistemas físicos (hardwares) e lógicos
            (softwares) do Sheep Finance, de seus fornecedores ou terceiros,
            para introduzir ou disseminar vírus informáticos ou quaisquer outros
            sistemas de hardware ou software que sejam capazes de causar danos
            anteriormente mencionados.
          </li>
        </ul>
        <h3
          style={{
            boxSizing: 'border-box',
            margin: '0px 0px 20px',
            lineHeight: '1.2',
            fontSize: '16px',
            letterSpacing: '-0.05em',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Mais informações
        </h3>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Esperemos que esteja esclarecido e, como mencionado anteriormente, se
          houver algo que você não tem certeza se precisa ou não, geralmente é
          mais seguro deixar os cookies ativados, caso interaja com um dos
          recursos que você usa em nosso site.
        </p>
        <p
          style={{
            boxSizing: 'border-box',
            marginTop: '0px',
            marginBottom: '1rem',
            fontSize: '16px',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          Esta política é efetiva a partir de
          <strong>7 setembro 2022 15:25</strong>
        </p>
      </div>
    </div>
  );
};

export default PolicyPrivacy;
