import { shade } from 'polished';

import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from '../../styles/colors.json';

import signInBgImg from '../../assets/sign-in-bg.jpeg';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBgImg}) no-repeat center;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
from{
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform: translateX(0);
}
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 20px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    button {
      margin-top: 16px;
    }
  }
`;

export const SignUpButton = styled(Link)`
  color: ${Colors.primary};
  margin-top: 16px;
  text-decoration: none;
  transition: color 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${shade(0.2, Colors.primary)};
  }

  svg {
    margin-right: 16px;
  }
`;
