import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Image } from './styles';
import dictionary from '../../../locale/pt/dictionary.json';

import { ProfileProps } from './interfaces';

const Profile: React.FC<ProfileProps> = ({ name, ...rest }) => {
  const {
    DashboardPage: { Header },
  } = dictionary;
  return (
    <Container>
      <Image {...rest} />
      <div>
        <span>{Header.Welcome}</span>
        <Link to="/profile">
          <strong>{name}</strong>
        </Link>
      </div>
    </Container>
  );
};

export default Profile;
