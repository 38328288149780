import React from 'react';

import { ScrollViewContainer } from './styles';
import { ScrollViewProps } from './interfaces';

const ScrollView: React.FC<ScrollViewProps> = ({ children }) => (
  <ScrollViewContainer>{children}</ScrollViewContainer>
);

export default ScrollView;
