import dayjs from 'dayjs';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { Plan } from '../../models/plan';

import api from '../../services/api';
import { PlanContextData, PlanState, Props } from './interfaces';
import { useDate } from '../Date';
import { PlanFormData } from '../../pages/Plan/interfaces';

const PlanContext = createContext<PlanContextData>({} as PlanContextData);

export const PlanProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<PlanState>({
    loading: false,
    plans: [],
  } as PlanState);

  const { endOfThisMonth } = useDate();

  const getPlan = useCallback(
    async (startDate: string, endDate: string) => {
      setData({
        ...data,
        loading: true,
      });

      const response = await api.get<Plan[]>(`plan/${startDate}/${endDate}`);

      const plans = response.data;

      let datedPlans = [] as Plan[];
      if (plans && plans.length > 0) {
        datedPlans = plans.map<Plan>((item: Plan) => {
          return {
            ...item,
            dueDate: dayjs(item.dueDate).add(1, 'd').toDate(),
          };
        }) as Plan[];
      }

      setData({
        loading: false,
        plans: datedPlans,
      });
    },
    [data],
  );

  const postPlan = useCallback(
    async (plan: PlanFormData) => {
      setData({
        ...data,
        loading: true,
      });
      try {
        const plans = data.plans ?? [];

        const response = await api.post('plan', plan);
        const { plan: newPlan } = response.data;

        const datedPlan: Plan = Object.assign(newPlan, {
          ...plan,
          category: newPlan.category,
          goal: newPlan.goal,
        });

        const newPlans = [...plans, datedPlan];

        const sortedPlans = newPlans
          .sort((a, b) =>
            a.dueDate
              .toLocaleString()
              .localeCompare(b.dueDate.toLocaleString()),
          )
          .reverse();

        setData({
          loading: false,
          plans: sortedPlans,
        });
      } catch (error) {
        setData({
          ...data,
          loading: false,
        });

        throw error;
      }
    },
    [data],
  );

  const deletePlan = useCallback(
    async (secureId: string) => {
      setData({
        ...data,
        loading: true,
      });

      try {
        const plans = data.plans ?? [];

        await api.delete(`plan/${secureId}`);

        setData({
          ...data,
          plans: plans.filter(plan => plan.secureId !== secureId),
          loading: false,
        });
      } catch (error) {
        setData({
          ...data,
          loading: false,
        });
        throw error;
      }
    },
    [data],
  );

  const updatePlan = useCallback(
    async (secureId: string, plan: PlanFormData) => {
      setData({
        ...data,
        loading: true,
      });
      try {
        await api.put<Plan>(`plan/${secureId}`, plan);

        setData({
          ...data,
          loading: true,
        });
      } catch (error) {
        setData({
          ...data,
          loading: false,
        });
        throw error;
      }
    },
    [data],
  );

  const finishPlan = useCallback(
    async (
      {
        secureId,
        goalId,
        value,
        description,
        category,
        goal,
        done,
        stop,
        repeat,
      }: Plan,
      status: boolean,
    ) => {
      const response = await api.put(`plan/finish/${secureId}`, {
        done: status,
      });

      const newPlanList = data.plans.map(p =>
        p.secureId === secureId ? { ...p, done: status } : p,
      );

      const { repeatPlan } = response.data;

      if (
        !dayjs(repeatPlan.dueDate).isAfter(endOfThisMonth) &&
        repeatPlan.secureId
      ) {
        newPlanList.push(
          Object.assign(repeatPlan, {
            goalId,
            value,
            description,
            category,
            goal,
            done,
            stop,
            repeat,
            dueDate: dayjs(repeatPlan.dueDate).add(1, 'd').toDate(),
          }),
        );
      }
      setData({
        plans: newPlanList.sort(
          (a, b) =>
            new Date(b.dueDate).valueOf() - new Date(a.dueDate).valueOf(),
        ),
      });
    },
    [data],
  );

  return (
    <PlanContext.Provider
      value={{
        loading: data.loading,
        plans: data.plans,
        getPlan,
        postPlan,
        deletePlan,
        updatePlan,
        finishPlan,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export function usePlan(): PlanContextData {
  const context = useContext(PlanContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
