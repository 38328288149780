import React, { useCallback, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { CalendarContainer } from './styles';

import { CalendarProps } from './interfaces';

const Calendar: React.FC<CalendarProps> = ({ onDayPress }) => {
  const [selectedDay, setSelectedDay] = useState(new Date());

  const handleDayClick = useCallback((day: Date) => {
    setSelectedDay(day);
    onDayPress(day);
  }, []);

  return (
    <CalendarContainer>
      <DayPicker
        modifiers={{}}
        selected={selectedDay}
        onDayClick={handleDayClick}
      />
    </CalendarContainer>
  );
};

export default Calendar;
