import dayjs from 'dayjs';
import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../../services/api';
import { DashboardContextData, DashboardState, Props } from './interfaces';

const DashboardContext = createContext<DashboardContextData>(
  {} as DashboardContextData,
);

export const DashboardProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<DashboardState>({} as DashboardState);

  const getDashboard = useCallback(async () => {
    setData({ ...data, loading: true });
    const response = await Promise.all([
      api.get<DashboardState>('dashboard/accounts'),
      api.get<DashboardState>('dashboard/earnings'),
      api.get<DashboardState>('dashboard/total'),
      api.get<DashboardState>('dashboard/timeline'),
    ]);
    const { accounts } = response[0].data;
    const { earnings } = response[1].data;
    const { totalBalance } = response[2].data;
    const { timeline } = response[3].data;
    const datedTimeLine = timeline.map(item => {
      return { ...item, date: dayjs(item.date).add(1, 'd').toDate() };
    });
    setData({
      earnings,
      accounts,
      totalBalance,
      timeline: datedTimeLine,
      loading: false,
    });
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        accounts: data.accounts,
        totalBalance: data.totalBalance,
        timeline: data.timeline,
        earnings: data.earnings,
        loading: data.loading,
        getDashboard,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export function useDashboard(): DashboardContextData {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
