import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }

  Button {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 0;
  }
`;

export const PasswordBlock = styled.div`
  padding-top: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -160px auto 0;

  width: 100%;
  max-width: 700px;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 24px;
      font-size: 20px;
      text-align: left;
    }

    input[name='oldPassword'] {
      margin-top: 24px;
    }

    button {
      margin-top: 16px;
    }
  }
`;
