import React, { useCallback, useRef } from 'react';
import { FiArrowLeft, FiMail, FiUser, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import {
  IFormatError,
  errorValidation,
  formatError,
} from '../../utils/errorValidation';

import dictionary from '../../locale/pt/dictionary.json';

import { Logo, Input, Button } from '../../components/atoms';
import { Container, Content, Background, AnimationContainer } from './styles';
import { SignUpFormData } from './interfaces';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const {
    SignUpPage: { ErrorsStrings, FormStrings, SuccessStrings },
  } = dictionary;
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(ErrorsStrings.NameRequired),
          email: Yup.string()
            .required(ErrorsStrings.EmailRequired)
            .email(ErrorsStrings.InvalidEmail),
          password: Yup.string()
            .required(ErrorsStrings.PasswordRequired)
            .min(6, ErrorsStrings.InvalidPassword),
          confirmPassword: Yup.string()
            .required(ErrorsStrings.ConfirmPasswordRequired)
            .oneOf(
              [Yup.ref('password'), ''],
              ErrorsStrings.PasswordDoesNotMatch,
            ),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/users', {
          username: data.name,
          email: data.email.toLowerCase(),
          password: data.password,
          password_confirmation: data.confirmPassword,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: SuccessStrings.ToastTitle,
          description: SuccessStrings.ToastMessage,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = errorValidation(err);

          formRef.current?.setErrors(errors);
          return;
        }

        const errors = formatError(err as IFormatError);

        addToast({
          type: 'error',
          title: ErrorsStrings.ToastTitle,
          description: errors[0].message,
        });
      }
    },
    [ErrorsStrings, SuccessStrings, history, addToast],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <Logo size="big" />
          <Form
            ref={formRef}
            initialData={{ name: '' }}
            onSubmit={handleSubmit}
          >
            <h1>{FormStrings.Title}</h1>

            <Input
              name="name"
              leftIcon={FiUser}
              placeholder={FormStrings.NamePlaceholder}
            />
            <Input
              name="email"
              leftIcon={FiMail}
              placeholder={FormStrings.EmailPlaceholder}
            />
            <Input
              name="password"
              leftIcon={FiLock}
              type="password"
              placeholder={FormStrings.PasswordPlaceholder}
            />
            <Input
              name="confirmPassword"
              leftIcon={FiLock}
              type="password"
              placeholder={FormStrings.ConfirmPasswordPlaceholder}
            />

            <Button type="submit">{FormStrings.ButtonRegister}</Button>
          </Form>
          <Link to="/">
            <FiArrowLeft />
            {FormStrings.LinkBack}
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
