import React, { useState } from 'react';
import {
  AiFillBank,
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineSwap,
} from 'react-icons/ai';
import { BiRocket, BiSupport } from 'react-icons/bi';
import { SiAppstore } from 'react-icons/si';
import { FaGooglePlay } from 'react-icons/fa';
import {
  FiMinusSquare,
  FiPlusSquare,
  FiUser,
  FiPieChart,
  FiCalendar,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';
import { Divider } from '@material-ui/core';
import { ScrollView, Button } from '../../atoms';
import { Container, MenuIcon, MenuItem, MenuItems } from './styles';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(true);

  const rightMenuAnimation = useSpring({
    transform: menuOpen ? `translateX(0%)` : `translateX(-80%)`,
  });

  return (
    <Container style={rightMenuAnimation}>
      <ScrollView>
        <MenuItems>
          <MenuIcon>
            <Button
              buttonType="transparent"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
            </Button>
          </MenuIcon>
          <MenuItem>
            <Link to="/profile">
              <div>
                <span>Perfil</span>
                <FiUser />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/accounts">
              <div>
                <span>Contas</span>
                <AiFillBank />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/incomings">
              <div>
                <span>Entradas</span>
                <FiPlusSquare />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/outgoings">
              <div>
                <span>Saídas</span>
                <FiMinusSquare />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/transfers">
              <div>
                <span>Transferências</span>
                <AiOutlineSwap />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/goals">
              <div>
                <span>Investimentos</span>
                <BiRocket />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/plan">
              <div>
                <span>Planejamento</span>
                <FiCalendar />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/cashflow">
              <div>
                <span>Fluxo de Caixa</span>
                <FiPieChart />
              </div>
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link to="/support">
              <div>
                <span>Suporte</span>
                <BiSupport />
              </div>
            </Link>
          </MenuItem>
          <MenuItem>
            <a
              href="https://play.google.com/store/apps/details?id=com.b13.sheep.finance"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <span>Play Store</span>
                <FaGooglePlay />
              </div>
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="https://apps.apple.com/us/app/sheep-finance/id1643414738"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <span>App Store</span>
                <SiAppstore />
              </div>
            </a>
          </MenuItem>
        </MenuItems>
      </ScrollView>
    </Container>
  );
};

export default Menu;
