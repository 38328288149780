import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('@SheepFinance:token');
      localStorage.removeItem('@SheepFinance:user');
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default api;
