import React, { useCallback, useRef, useState } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import {
  IFormatError,
  errorValidation,
  formatError,
} from '../../utils/errorValidation';

import { Logo } from '../../components/atoms';
import {
  Content,
  Background,
  AnimationContainer,
  Container,
  SignUpButton,
} from './styles';
import dictionary from '../../locale/pt/dictionary.json';
import { SignInFormData } from './interfaces';
import Login from '../../components/organisms/Login';
import ConfirmCode from '../ConfirmCode';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [showLoginForm, setShowLoginForm] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const {
    SignInPage: { ErrorsStrings, FormStrings, SuccessStrings },
  } = dictionary;

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data: SignInFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .required(ErrorsStrings.EmailRequired)
          .email(ErrorsStrings.InvalidEmail),
        password: Yup.string().required(ErrorsStrings.PasswordRequired),
      });

      await schema.validate(data, { abortEarly: false });

      await signIn({
        email: data.email.toLowerCase(),
        password: data.password,
      });

      addToast({
        type: 'success',
        title: SuccessStrings.ToastTitle,
        description: SuccessStrings.ToastMessage,
      });
    } catch (err: unknown) {
      const error = err as {
        response: {
          status: number;
        };
      };

      if (error.response.status === 403) {
        setEmail(data.email.toLowerCase());
        setShowLoginForm(false);
      }

      if (err instanceof Yup.ValidationError) {
        const errors = errorValidation(err);

        formRef.current?.setErrors(errors);
        setLoading(false);
        return;
      }

      const errors = formatError(err as IFormatError);

      addToast({
        type: 'error',
        title: ErrorsStrings.ToastTitle,
        description: errors[0].message,
      });
    }
    setLoading(false);
  }, []);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          {showLoginForm ? (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Logo size="big" />
              <Login loading={loading} />
              <SignUpButton to="/signup">
                <FiLogIn />
                {FormStrings.LinkRegister}
              </SignUpButton>
            </Form>
          ) : (
            <ConfirmCode email={email} successCallback={setShowLoginForm} />
          )}
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
